'use strict';

const processInclude = require('base/util');
const isMobile = require('../util/isMobile');
const Login = require('../login/login');

const LoginFlyout = {};

LoginFlyout.init = () => {
    if ($('.page').data('action') === 'Login-Show') return;

    LoginFlyout.onHolderHover();
    LoginFlyout.onHolderLeave();
    LoginFlyout.onCloseClick();
    LoginFlyout.onHolderClick();
    LoginFlyout.onLoginSubmit();
};

/**
 * Appends the html of the flyout to the holder via API call.
 * @param {function} successCallback - the callback function to execute after the api call or when html of the flyout has already been appended.
 */
LoginFlyout.loadFlyoutHtml = (successCallback) => {
    const $flyoutHolder = $('.js-login-flyout-holder');
    if ($flyoutHolder.find('.js-login-flyout').length) {
        successCallback();
        return;
    }

    const url = $flyoutHolder.data('flyout-url'); // Login-Flyout
    $.get(url, (result) => {
        $flyoutHolder.append(result);
        successCallback();
        processInclude(require('../components/clientSideValidation'));
    });
};

LoginFlyout.onHolderHover = () => {
    $('body').on('mouseenter focusin touchstart', '.js-login-flyout-holder', (event) => {
        if (isMobile.any()) return;
        try {
            if ($(event.target).is(':-webkit-autofill')) return;
        } catch (error) {
            // do nothing, pseudo selector :-webkit-autofill is only supported on webkit and will throw an error on other browsers
        }
        LoginFlyout.loadFlyoutHtml(() => {
            require('../components/form').initViewPassword();
            const flyout = $('.js-login-flyout-holder').children('.js-login-flyout');
            LoginFlyout.show(flyout);
        });
    });
};

LoginFlyout.onHolderLeave = () => {
    $('body').on('mouseleave', '.js-login-flyout-holder', (event) => {
        if (isMobile.any() || $('.js-login-flyout form input').is(':focus')) return;

        const flyout = $(event.currentTarget).children('.js-login-flyout');
        LoginFlyout.hide(flyout);
    });
};

LoginFlyout.onCloseClick = () => {
    $('body').on('click keyup', '.js-login-flyout-close', (event) => {
        if (event.type !== 'click' && event.key !== 'Enter') return;

        const flyout = $(event.currentTarget).parent('.js-login-flyout');
        LoginFlyout.hide(flyout);
    });
};

LoginFlyout.onHolderClick = () => {
    if (isMobile.any()) {
        const Dialog = require('../util/dialog');

        $('body').on('click', '.js-login-flyout-holder', function (event) {
            event.preventDefault();

            LoginFlyout.loadFlyoutHtml(() => {
                Dialog.show(Dialog.TYPE_CONFIRM, $('.js-login-flyout').data('title'), null, null, '.js-login-flyout', null, [require('../components/form')], false);
                $('.modal-body__container').addClass(['login-flyout', 'login-flyout-mobile']); // Add extra styling for popup to handle differences between the hover flyout
            });
        });
    }
};

LoginFlyout.onLoginSubmit = () => {
    const loginFormSelector = isMobile.any() ? '.login-flyout-mobile form.login' : '.js-login-flyout form.login';
    Login.onLoginSubmit(loginFormSelector, () => {
        location.reload();
    });
};

LoginFlyout.hide = (flyout) => {
    flyout.removeClass('show');
    setTimeout(() => {
        flyout.attr('aria-hidden', 'true');
    }, 100);
};

LoginFlyout.show = (flyout) => {
    flyout.attr('aria-hidden', 'false');
    setTimeout(() => {
        flyout.addClass('show');
    }, 100);
};

module.exports = LoginFlyout;
