'use strict';

const isMobile = require('../util/isMobile');
const PageFlyout = require('../util/pageFlyout');

const Navigation = {};

Navigation.init = () => {
    if (isMobile.any()) {
        Navigation.initMobile();
    } else {
        Navigation.initDesktop();
    }

    Navigation.initUSP();
    Navigation.initFreeHtml();
    Navigation.initMyTorfsMenu();
};

Navigation.initMobile = () => {
    PageFlyout.init('.js-nav-menu-mobile', '.js-nav-menu-mobile-open', Navigation.loadMobileHtml, null, null);
};

Navigation.initTabs = () => {
    $('.js-nav-menu-tab-item').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        const targetID = $this.data('for');
        localStorage.setItem('preferredMobileMenuTab', targetID);
        $('.js-nav-menu-tab-item').removeClass('active');
        $this.addClass('active');

        const targetSelector = `#${targetID}`;
        $('.js-tab-content').not(targetSelector).hide();

        $('.js-nav-sub-menu').hide();
        $('.js-nav-sub-menu').removeClass('show');
        Navigation.showTopLevelNav();
    });

    const preferredMobileMenuTab = localStorage.getItem('preferredMobileMenuTab');
    if (preferredMobileMenuTab) {
        $(`.js-nav-menu-tab-item[data-for="${preferredMobileMenuTab}"]`).trigger('click');
    } else {
        $('.js-nav-menu-tab-item').first().trigger('click');
    }
};

Navigation.initSubCategoriesNavigation = () => {
    const $flyoutContainer = $('.js-nav-menu-mobile .page-flyout__container');

    $('.js-nav-menu-item-has-sub-categories').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        if (!$this.hasClass('js-nav-menu-item-has-sub-categories')) {
            const url = $this.prop('href');
            location.href = url;
            return;
        }

        if ($this.parents('.js-nav-sub-menu').length) {
            Navigation.hideSecondLevelNav($this);
        } else {
            Navigation.hideTopLevelNav($this);
        }

        const targetID = $this.data('for');
        const $target = $(`#${targetID}`);
        $target.find('> .nav-menu-mobile__nav-item').show();
        $target.show();

        setTimeout(() => {
            $target.addClass('show');
            $flyoutContainer.scrollTop(0);
        }, 50);

        // GDDL tracking
        if ($target.data('category-id') === 'Merken') {
            const $brandImageSection = $target.find('.nav-menu-mobile__nav-item--images');
            if ($brandImageSection.length > 0) {
                const brands = [];
                $brandImageSection.find('img').each((i, el) => {
                    brands.push($(el).data('brand-name'));
                });
                $(document).trigger('brandLogosNavMenu:impression', {
                    brands: brands
                });
            }
        } else if ($target.data('category-id') === 'Topmerken') {
            $(document).trigger('topBrandsNavMenu:impression');
        }
    });

    $('.js-nav-menu-back').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        $this.parent('.js-nav-sub-menu').removeClass('show');
        setTimeout(() => {
            $this.parent('.js-nav-sub-menu').hide();
            if ($this.parents('.js-nav-sub-menu').length > 1) {
                Navigation.showSecondLevelNav($this);
            } else {
                Navigation.showTopLevelNav();
            }
        }, 150);
        $flyoutContainer.scrollTop(0);
    });
};

Navigation.showTopLevelNav = () => {
    $('.js-nav-menu-banner').show();
    $('.nav-menu-mobile__nav-group > .nav-menu-mobile__nav-item').show();
    $('.nav-menu-mobile__nav-group > .content-asset').show();
    $('.js-top-level-nav-group').show();
    const preferredMobileMenuTab = localStorage.getItem('preferredMobileMenuTab');
    $(`#${preferredMobileMenuTab}`).show();
};

Navigation.hideTopLevelNav = ($selectedElement) => {
    $('.js-nav-menu-banner').hide();
    $('.nav-menu-mobile__nav-group > .nav-menu-mobile__nav-item').hide();
    $('.nav-menu-mobile__nav-group > .content-asset').hide();
    $('.js-top-level-nav-group').hide();
    $('.js-tab-content').hide();
    $selectedElement.closest('.js-top-level-nav-group, .js-tab-content').show();
};

Navigation.showSecondLevelNav = ($selectedElement) => {
    $selectedElement.parent().parents('.js-nav-sub-menu').find('> .nav-menu-mobile__nav-item').show();
};

Navigation.hideSecondLevelNav = ($selectedElement) => {
    $selectedElement.parents('.js-nav-sub-menu').find('> .nav-menu-mobile__nav-item').hide();
};

Navigation.loadMobileHtml = () => {
    const $navMenuMobile = $('.js-nav-menu-mobile');
    if ($navMenuMobile.hasClass('nav-menu-loaded')) return;

    const key = `mobileNavMenuHtml-${$('html').attr('lang')}`;
    const sessionMobileMenuHtml = sessionStorage.getItem(key);
    if (sessionMobileMenuHtml) {
        $navMenuMobile.find('.page-flyout__container').prepend(sessionMobileMenuHtml);
        $navMenuMobile.addClass('nav-menu-loaded');
        Navigation.initTabs();
        Navigation.initSubCategoriesNavigation();
        return;
    }

    const url = $navMenuMobile.data('get-mobile-nav-url'); // Page-IncludeHeaderMenu
    $.get(url, data => {
        $navMenuMobile.find('.page-flyout__container').prepend(data);
        $navMenuMobile.addClass('nav-menu-loaded');
        Navigation.initTabs();
        Navigation.initSubCategoriesNavigation();
        sessionStorage.setItem(key, data);
    });
};

Navigation.loadSubNavDesktop = ($category) => {
    if ($category.hasClass('sub-nav-loaded')) return;

    const categoryID = $category.data('category-id');
    const key = `desktopNavMenuHtml-${categoryID}-${$('html').attr('lang')}`;
    const sessionNavMenuHtml = sessionStorage.getItem(key);
    if (sessionNavMenuHtml) {
        $category.append(sessionNavMenuHtml);
        $category.addClass('sub-nav-loaded');
        return;
    }

    const url = $category.data('get-sub-nav-url'); // Page-HeaderMenuSubNavigation
    $.get(url, data => {
        $category.append(data);
        $category.addClass('sub-nav-loaded');
        sessionStorage.setItem(key, data);
    });
};

Navigation.initDesktop = () => {
    Navigation.initTextRotator();
    Navigation.initNavigationDesktop();
};

Navigation.initTextRotator = () => {
    $('.header-text-rotator').children('li').each(function () {
        const $element = $(this);

        if ($element.text().trim() === '') {
            $element.remove();
        }
    });
    const textsToRotate = $('.header-text-rotator li').toArray();
    if (textsToRotate.length <= 0) return;

    let index = 0;
    setInterval(() => {
        $('.header-text-rotator li').removeClass('active');
        $(textsToRotate[index]).addClass('active');
        index++;
        if (index >= textsToRotate.length) {
            index = 0;
        }
    }, 5000);
};

Navigation.initUSP = () => {
    if ($('.js-usp-countdown').length) {
        require('./uspCountdownClock').init();
    }
    let removedBanners = JSON.parse(sessionStorage.getItem('freeHTML')) || [];
    if (removedBanners.indexOf('usp') === -1) {
        $('.header-usp-wrapper').removeClass('d-none');
    }
    if ($('.header-usp:visible').length) {
        const $uspClose = $('.header-usp-close');
        $uspClose.removeClass('d-none');
        $uspClose.on('click', (e) => {
            e.preventDefault();
            $('.header-usp-wrapper').addClass('d-none');
            removedBanners.push('usp');
            sessionStorage.setItem('freeHTML', JSON.stringify(removedBanners));
        });
    }
};

Navigation.initFreeHtml = () => {
    let removedBanners = JSON.parse(sessionStorage.getItem('freeHTML')) || [];
    $('.header-freeHTML-js').each(function () {
        if (removedBanners.indexOf($(this).data('identifier')) === -1) {
            $(this).removeClass('d-none');
        }
    });
    $('.header-freeHTML-close').on('click', function () {
        $(this).parent().hide();
        let freeHTML = JSON.parse(sessionStorage.getItem('freeHTML')) || [];
        freeHTML.push($(this).closest('.header-freeHTML-js').data('identifier'));
        sessionStorage.setItem('freeHTML', JSON.stringify(freeHTML));
    });
};

/**
 * Appends the html of the my torfs menu to the holder via API call.
 * @param {function} successCallback - the callback function to execute after the api call or when html of the menu has already been appended.
 */
Navigation.loadMyTorfsMenuHtml = (successCallback) => {
    const $menu = $('.mytorfs-holder');
    if ($menu.find('.mytorfs-menu').length) {
        successCallback();
        return;
    }

    const url = $menu.data('menu-url'); // Account-UserMenu
    $.get(url, result => {
        $menu.append(result);
        successCallback();
    });
};

Navigation.initMyTorfsMenu = () => {
    if (isMobile.any()) {
        const Dialog = require('../util/dialog');
        $('body').on('click', '.mytorfs-holder', function (e) {
            e.preventDefault();
            Navigation.loadMyTorfsMenuHtml(() => {
                Dialog.show(Dialog.TYPE_CONFIRM, $('.mytorfs-menu').data('title'), null, null, '.mytorfs-menu', null, [], false);
            });
        });
    } else {
        $('body').on('mouseenter focusin touchstart', '.mytorfs-holder', function () {
            Navigation.loadMyTorfsMenuHtml(() => {
                var menu = $('.mytorfs-holder').children('.mytorfs-menu');
                menu.attr('aria-hidden', 'false');
                setTimeout(function () {
                    menu.addClass('show');
                }, 100);
            });
        });
        $('body').on('mouseleave', '.mytorfs-holder', function () {
            var menu = $(this).children('.mytorfs-menu');
            menu.removeClass('show');
            setTimeout(function () {
                menu.attr('aria-hidden', 'true');
            }, 100);
        });
        $('body').on('click keyup', '.mytorfs-close', function (event) {
            if (event.type !== 'click' && event.key !== 'Enter') return;

            var menu = $(this).parent('.mytorfs-menu');
            menu.removeClass('show');
            setTimeout(function () {
                menu.attr('aria-hidden', 'true');
            }, 100);
        });
    }
};

Navigation.initNavigationDesktop = () => {
    var $body = $('body');
    if (navigator.userAgent.toLowerCase().indexOf('edge') > -1) {
        $body.addClass('edge edge--no-chromium');
    }

    /**
     * Initializes the main menu hover states.
     */
    function navigation() {
        let timeouts = [];
        let mouseOverCount = 0;

        /* Main Menu Hover and Leave */
        $('body').on('mouseleave', 'li.main-nav-item.has-mega-nav', function () {
            timeouts.forEach(function (timeout) {
                clearTimeout(timeout);
            });

            timeouts = [];

            const $this = $(this);

            setTimeout(function () {
                $('.mega-nav-container').addClass('transition');
                $($this).find('.hvr').first().attr('aria-hidden', 'true');
                $this.removeClass('open');
            }, 50);

            // reset count
            mouseOverCount = 0;
        });

        $('body').on('mouseover', 'li.main-nav-item.has-mega-nav', function () {
            const $this = $(this);
            Navigation.loadSubNavDesktop($this);
            $this.addClass('open');

            timeouts.push(setTimeout(function () {
                $this.find('.hvr').first().attr('aria-hidden', 'false');

                // trigger GDDL tracking event for Brands category
                if (mouseOverCount < 1 && $this.data('category-id') === 'Merken') {
                    const $brandImageSection = $this.find('.mega-nav-section--images');
                    if ($brandImageSection.length > 0) {
                        const brands = [];
                        $brandImageSection.find('img').each((i, el) => {
                            brands.push($(el).data('brand-name'));
                        });
                        $(document).trigger('brandLogosNavMenu:impression', {
                            brands: brands
                        });
                    }
                    $(document).trigger('topBrandsNavMenu:impression');
                    mouseOverCount++;
                }
            }, 200));
        });
    }

    navigation();
};

module.exports = Navigation;
