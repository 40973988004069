'use strict';

const UspCountdownClock = {};

UspCountdownClock.init = () => {
    const $countdown = $('.js-usp-countdown');
    const $countdownDays = $countdown.find('.js-usp-countdown-days');
    const $countdownHours = $countdown.find('.js-usp-countdown-hours');
    const $countdownMinutes = $countdown.find('.js-usp-countdown-minutes');
    const $countdownSeconds = $countdown.find('.js-usp-countdown-seconds');
    const endDate = Date.parse($countdown.data('end-date'));

    UspCountdownClock.updateTimeLeft(endDate, $countdownDays, $countdownHours, $countdownMinutes, $countdownSeconds);

    const interval = setInterval(() => {
        UspCountdownClock.updateTimeLeft(endDate, $countdownDays, $countdownHours, $countdownMinutes, $countdownSeconds, interval);
    }, 1000);
};

/**
 * Updates the countdown clock html
 * @param {Date} endDate - end date of the countdown
 * @param {jQuery} $countdownDays - jQuery element of countdown days left
 * @param {jQuery} $countdownHours - jQuery element of countdown hours left
 * @param {jQuery} $countdownMinutes - jQuery element of countdown minutes left
 * @param {jQuery} $countdownSeconds - jQuery element of countdown seconds left
 * @param {NodeJS.Timeout} interval - The interval of the countdown timer
 */
UspCountdownClock.updateTimeLeft = (endDate, $countdownDays, $countdownHours, $countdownMinutes, $countdownSeconds, interval) => {
    const timeDifference = endDate - Date.now();
    const msDay = 1000 * 60 * 60 * 24;
    const msHour = 1000 * 60 * 60;
    const msMinute = 1000 * 60;
    const days = Math.floor(timeDifference / msDay);
    const hours = Math.floor((timeDifference % msDay) / msHour);
    const minutes = Math.floor((timeDifference % msHour) / msMinute);
    const seconds = Math.floor((timeDifference % msMinute) / 1000);

    const $countDownDaysLabel = $countdownDays.parent().find('.js-usp-countdown-days-label');
    if (days === 1) {
        $countDownDaysLabel.text($countDownDaysLabel.data('day-unit-singular'));
    } else if (days < 1) {
        $countdownDays.hide();
        $countDownDaysLabel.hide();
    }

    $countdownSeconds.text(seconds.toString().padStart(2, '0'));
    $countdownMinutes.text(minutes.toString().padStart(2, '0'));
    $countdownHours.text(hours.toString().padStart(2, '0'));
    $countdownDays.text(days.toString());

    if (timeDifference <= 0) {
        $countdownHours.parents('.header-usp-wrapper').addClass('d-none');

        if (interval) {
            clearInterval(interval);
        }
    }
};

module.exports = UspCountdownClock;
