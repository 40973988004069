'use strict';

const openingHours = require('../util/openingHours');

const FavoriteStore = {};

FavoriteStore.init = () => {
    FavoriteStore.updateOpeningHours();
};

FavoriteStore.updateOpeningHours = () => {
    if ($('.store-locator-details').length > 0) {
        openingHours.updateTodayOpenUntilHours('.store-locator-details', '.store-locator-details__hours--open-closed', 'store-locator-details__hours--open', 'store-locator-details__hours--closed');
    }
    if ($('.header-favorite-store__hours').length > 0) {
        openingHours.updateTodayOpenUntilHours('.header-favorite-store', '.header-favorite-store__hours--open-closed', 'header-favorite-store__hours--open', 'header-favorite-store__hours--closed');
    }
};

module.exports = FavoriteStore;
